<template>
  <v-dialog v-model="show" max-width="280px" persistent>
    <v-card>
      <v-card-title class="py-0 px-1">
        <v-spacer></v-spacer>
        <v-icon @click="togglePlayerModal({ show: false })">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form lazy-validation ref="emailForm">
          <v-row no-gutters>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Name"
                outlined
                dense
                v-model="nick_name"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Email"
                outlined
                dense
                v-model="email"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
                :rules="[rules.email]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0;mb-6">
              <v-text-field
                label="Phone"
                outlined
                dense
                v-model="phone"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
                :maxlength="max"
                @keypress="isNumber($event)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" style="margin-top: 16px">
              <v-btn
                class="text-capitalize"
                width="100%"
                dark
                color="#7253CF"
                :loading="formLoading"
                @click="submit"
              >
                <span>Save</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
export default {
  name: "PlayerModal",

  data() {
    return {
      rules: RULES,
      formLoading: false,
      email: "",
      nick_name: "",
      phone: "",
      formatedn: "",
      max: 10,
    };
  },
  computed: {
    ...mapGetters({
      getShow: "publicDonationPage/getPlayer",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.togglePlayerModal({ show: value });
      },
    },
    type() {
      return this.$store.state.publicDonationPage.inviteViaMailPlayer.type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      togglePlayerModal: "publicDonationPage/togglePlayerModal",
    }),
    onUpdate(payload) {
      this.results = payload;
    },
    openModal() {
      if (this.type === "edit") {
        this.nick_name =
          this.$store.state.publicDonationPage.inviteViaMailPlayer.playerDetail.nick_name;
        this.email =
          this.$store.state.publicDonationPage.inviteViaMailPlayer.playerDetail.email;
        this.phone =
          this.$store.state.publicDonationPage.inviteViaMailPlayer.playerDetail.phone;
      }
    },
    closeModal() {
      this.$refs.emailForm.reset();
      this.formLoading = false;
    },
    submit() {
      this.formLoading = true;
      let id =
        this.$store.state.publicDonationPage.inviteViaMailPlayer.playerId;
      this.$root.$refs.InviteViaMail.updatePlayer(
        { nick_name: this.nick_name, email: this.email, phone: this.phone },
        id
      );
      this.formLoading = false;
      this.togglePlayerModal({ show: false });
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
</style>
